import React from "react";
import { Button } from "../components/Button/Button";
import { Dropdown } from "../components/Dropdown/Dropdown";
import { FileUpload } from "../components/FileUpload/FileUpload";
import { Form } from "../components/Form/Form";
import { Input } from "../components/Input/Input";
import { Select } from "../components/Select/Select";
import styles from './UserProfile.module.css';

export const UserProfile: React.FC = (props) => {
    
    const onSubmitHandler = (formData: object) => {
        console.log(formData);
    }

    return (
        <>
            <h1 className="glow-text">Personal Details</h1>
            <Form onSubmit={onSubmitHandler} className={ styles.form }>
                <fieldset>
                    <Input name="fullname" label="Full name" type="text" />
                    <Input name="email" label="Email" type="email" />
                    <div className="fields">
                        <Select name="countryCode" options={['+91', '+43']} label="Phone" placeholder="Select"/>
                        <Input name="phone" label="" type="tel" className="span-full"/>
                    </div>
                    <Select name="country" options={['India', 'United Kingdom', 'United States', 'Germany']} label="Country" placeholder="Select" />
                </fieldset>
                <fieldset>
                    <Input name="domain" label="Domains" type="text" />
                    <Input name="expectedCTC" label="Expected CTC" type="number" />
                    <Input name="preferableLocations" label="Preferable Locations" type="text" />
                    <FileUpload fileLimit={2}/>
                    <Button type="submit">Save</Button>
                </fieldset>
            </Form>
        </>
    );
}