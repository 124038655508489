import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { toasterActions } from "../../store/toaster-slice";
import styles from "./FileUpload.module.css";

interface FileUploadProps {
    onUpload?: (file: FileList) => void,
    fileLimit: number
}

export const FileUpload: React.FC<FileUploadProps> = (props) => {
    const [files, setFiles] = useState<any[]>([]);
    const dispatch = useDispatch();

    const onDrop = useCallback(acceptedFiles => {
        const numberOfFiles = files.length + acceptedFiles.length;
        if (numberOfFiles <= props.fileLimit) {
            const newFiles = acceptedFiles.map((file: File) => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            setFiles(files => [...files, ...newFiles]);
        } else {
            dispatch(toasterActions.addToast({ id: 'FILE_LIMIT_ERROR', title: 'Error!', message: `Number of files limited to ${props.fileLimit}`, type: 'error' }))
        }
    }, []);

    const removeFileHandler = (index: number) => {
        setFiles(files => files.filter((file, idx) => idx !== index))
    }

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png'
    });

    // clean up
    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const style = useMemo(() => `${styles.dropzone} ${isDragActive ? styles.active : ''} ${isDragAccept ? styles.accept : ''} ${isDragReject ? styles.reject : ''}`
    , [ isDragActive, isDragReject, isDragAccept ]);

    return (
        <div>
            { files.length < props.fileLimit && <div { ...getRootProps({className: style}) }>
                <input { ...getInputProps() }/>
                <div className={ styles.description }>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                    </svg>
                    Upload your <span>Resume / CV</span>
                </div>
            </div> }           
            <aside className={ styles['dropzone-files'] }>
                { files.map((file, idx) => 
                    <div key={idx}>
                        <p>{ file.name }</p> 
                        <button className="icon-button" onClick={ e => removeFileHandler(idx) }>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                ) }
            </aside>
        </div>
    );
}