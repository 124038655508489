import { IJobGist } from "../../models/Job.model";
import styles from "./Jobs.module.css";
import { ReactComponent as UserIcon } from "../../assets/svg/icon-user.svg";
import { ReactComponent as BookmarkIcon } from "../../assets/svg/bookmark-icon.svg";
import { ReactComponent as UserCheckIcon } from "../../assets/svg/user-check-icon.svg";
import { ReactComponent as VtDotsIcon } from "../../assets/svg/vertical-dots-icon.svg";
import { useNavigate } from "react-router-dom";
import { addRouteParams, ROUTES } from "../../routes";
import { useEffect, useState } from "react";

const INITIAL_JOBS: IJobGist[] = [
    {
        id: '86293',
        title: 'Software Developer',
        position: 'Junior Dev',
        type: 'full-time',
        role: 'Frontend developer',
        profiles: 3907,
        selected: 10,
        bookmarked: 2,
        createdOn: '13th Jul 2021',
        status: 'active',
    },
    {
        id: '32906',
        title: 'Software Developer',
        position: 'Junior Dev',
        type: 'full-time',
        role: 'Frontend developer',
        profiles: 3907,
        selected: 10,
        bookmarked: 2,
        createdOn: '13th Jul 2021',
        status: 'inactive',
    },
    {
        id: '78382',
        title: 'Software Developer',
        position: 'Junior Dev',
        type: 'full-time',
        role: 'Frontend developer',
        profiles: 3907,
        selected: 10,
        bookmarked: 2,
        createdOn: '13th Jul 2021',
        status: 'active',
    },
    {
        id: '02392',
        title: 'Software Developer',
        position: 'Junior Dev',
        type: 'full-time',
        role: 'Frontend developer',
        profiles: 3907,
        selected: 10,
        bookmarked: 2,
        createdOn: '13th Jul 2021',
        status: 'active',
    },
    {
        id: '12782',
        title: 'Software Developer',
        position: 'Junior Dev',
        type: 'full-time',
        role: 'Frontend developer',
        profiles: 3907,
        selected: 10,
        bookmarked: 2,
        createdOn: '13th Jul 2021',
        status: 'inactive',
    },
    {
        id: '92392',
        title: 'Software Developer',
        position: 'Junior Dev',
        type: 'full-time',
        role: 'Frontend developer sas',
        profiles: 3907,
        selected: 10,
        bookmarked: 2,
        createdOn: '13th Jul 2021',
        status: 'inactive',
    },
    {
        id: '70371',
        title: 'Software Developer',
        position: 'Junior Dev',
        type: 'full-time',
        role: 'Frontend developer sas',
        profiles: 3907,
        selected: 10,
        bookmarked: 2,
        createdOn: '13th Jul 2021',
        status: 'active'
    },
    {
        id: '92303',
        title: 'Software Developer',
        position: 'Junior Dev',
        type: 'full-time',
        role: 'Frontend developer sas',
        profiles: 3907,
        selected: 10,
        bookmarked: 2,
        createdOn: '13th Jul 2021',
        status: 'inactive',
    },
    {
        id: '19371',
        title: 'Software Developer',
        position: 'Junior Dev',
        type: 'full-time',
        role: 'Frontend developer sas',
        profiles: 3907,
        selected: 10,
        bookmarked: 2,
        createdOn: '13th Jul 2021',
        status: 'active'
    }
    
];

export const Jobs: React.FC = (props) => {

    const [jobs, setJobs] = useState<{ active: IJobGist[], inactive: IJobGist[] }>(),
        [jobMenu, setJobMenu] = useState<string>(''), 
        [viewAll, setViewAll] = useState({
            active: false,
            finished: false
        });
    const navigate = useNavigate();
    console.log(jobMenu);

    const assignJobs = (jobs: IJobGist[], trim: boolean = false) => {
        let inactiveJobs: IJobGist[] = [], activeJobs: IJobGist[] = [];
        for (const job of jobs) {
            if (job.status === 'active') activeJobs.push(job);
            else inactiveJobs.push(job);
        }
        console.log(trim);
        
        setJobs({
            active: trim ? activeJobs.slice(0, 4) : activeJobs,
            inactive: trim ? inactiveJobs.slice(0, 4) : inactiveJobs
        });
    }

    useEffect(() => {
        // TODO: Fetch all the Jobs of the Recruiter
        const limit = viewAll ? -1 : 10;
        if (viewAll) {
            assignJobs(INITIAL_JOBS, false)
        } else {
            assignJobs(INITIAL_JOBS, true);
        }
    }, [viewAll])
    
    useEffect(() => {
        // TODO: Fetch only last 10 Jobs of the Recruiter
        // fetchJobs(10)
            // .then(_ => setJobs(e.data))
        assignJobs(INITIAL_JOBS, true);
        
    }, []);

    const redirectToProfiles = (jobId: string) => {
        const route = addRouteParams(ROUTES.jobProfiles, jobId);
        navigate(route);
    }

    const toggleViewAll = (key: 'active' | 'finished') => {
        setViewAll(state => ({...state, [key]: !state[key]}));
    }

    const toggleMenu = (jobId: string) => {
        setJobMenu(state => state === jobId ? '' : jobId);
    }
    
    return (
        <>
            <section className={styles['jobs-section']}>
                <div className={styles['section-header']}>
                    <div>
                        <h3>Active Jobs</h3>
                        <p className="subtle-text">Recent --&gt; Oldest</p>
                    </div>
                    <button onClick={ _ => toggleViewAll('active') }>View All</button>
                </div>
                <div className={`${styles.cards} ${viewAll.active && styles['view-all']}`}>
                    {
                        jobs?.active.map(job => (
                            <div key={job.id} className={styles.card}>
                                <div className={styles.header}>
                                    <span className="subtle-text">#{ job.id } • { job.createdOn }</span>
                                    <p>{ job.title }</p>
                                    <span>{ job.position } • { job.type } • { job.role }</span>
                                    <div className={`${styles['header-option']} ${jobMenu === job.id ? styles.open : ''}`} tabIndex={0} onClick={ e => e.stopPropagation() } onFocus={ _=> setJobMenu(job.id) } onBlur={ _ => setJobMenu('') }>
                                        <VtDotsIcon />
                                        <ul>
                                            <li onClick={ _ => redirectToProfiles(job.id) }>View</li>
                                            <li>Edit</li>
                                            <li>Mark as Completed</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles.body}>
                                    <p>
                                        <UserIcon />
                                        { job.profiles }
                                    </p> 
                                    <p>
                                        <BookmarkIcon />
                                        { job.bookmarked }
                                    </p> 
                                    <p>
                                        <UserCheckIcon />
                                        { job.selected }
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </section>
            <section className={styles['jobs-section']}>
                <div className={styles['section-header']}>
                    <div>
                        <h3>Finished Jobs</h3>
                        <p className="subtle-text">Recent --&gt; Oldest</p>
                    </div>
                    <button onClick={ _ => toggleViewAll('finished') }>View All</button>
                </div>
                <div className={`${styles.cards} ${viewAll.finished && styles['view-all']}`}>
                {
                        jobs?.inactive.map(job => (
                            <div key={job.id} className={styles.card}>
                                <div className={styles.header}>
                                    <span className="subtle-text">#{ job.id } • { job.createdOn }</span>
                                    <p>{ job.title }</p>
                                    <span>{ job.position } • { job.type } • { job.role }</span>
                                    <div className={`${styles['header-option']} ${jobMenu === job.id ? styles.open : ''}`} tabIndex={0} onClick={ e => e.stopPropagation() } onFocus={ _=> setJobMenu(job.id) } onBlur={ _ => setJobMenu('') }>
                                        <VtDotsIcon />
                                        <ul>
                                            <li onClick={ _ => redirectToProfiles(job.id) }>View</li>
                                            <li>Edit</li>
                                            <li>Mark as Completed</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles.body}>
                                    <p>
                                        <UserIcon />
                                        { job.profiles }
                                    </p> 
                                    <p>
                                        <BookmarkIcon />
                                        { job.bookmarked }
                                    </p> 
                                    <p>
                                        <UserCheckIcon />
                                        { job.selected }
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </section>
        </>
    );
}