import styles from './AppLayout.module.css';
import { ReactComponent as UserIcon } from "../assets/svg/icon-user.svg";
import { ReactComponent as BellIcon } from "../assets/svg/icon-bell.svg";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { useState } from 'react';

export const AppLayout: React.FC = (props) => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>();

    return (
        <div className={styles.layout}>
            <Sidebar setIsCollapsed={setIsSidebarCollapsed} />
            <div className={`${styles.main} ${isSidebarCollapsed && styles.expand}`}>
                <nav className={ styles.navbar }>
                    <ul>
                        <li>
                            <BellIcon />
                        </li>
                        <li>
                            <UserIcon />
                        </li>
                    </ul>
                </nav>
                {props.children}
            </div>
        </div>
    );
}