import React from "react";
import classes from "./Button.module.css";

export const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
    
    const { children, className, ...attributes } = props;

    return (
        <button className={ `${classes.button} ${className}` } { ...attributes }>{ children }</button>
    );
}