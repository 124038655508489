import styles from "./ExpandableItem.module.css";
import React, { useState } from "react";
import { ExpandableProps } from "./ExpandableList";


export const ExpandableItem: React.FC<ExpandableProps<any>> = (props) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false); 
    
    return (
        <li className={styles.item}>
            { React.createElement(props.item, { data: props.data, expand: setIsExpanded, isExpanded: isExpanded }) }
            <div className={`${styles.expandable} ${isExpanded ? styles.expand : ''}`}>
                { React.createElement(props.expand, { data: props.data }) }
            </div>
        </li>
    );
} 