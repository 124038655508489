import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";

export const API = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_ENV}/v1`,
    headers: {
        'Content-Type': 'application/json'
    }
})

const access_token = localStorage.getItem('access_token');

export const SecuredAPI = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
    }
})


const makeRequest = async (config: AxiosRequestConfig, guarded: boolean = false) => {
    const { url, method, headers, data, baseURL, ...other } = config;
    let requestHeaders: AxiosRequestHeaders = {
        'Content-type': 'application/json',
        ...config.headers
    }
    if (guarded) {
        const access_token = localStorage.getItem('access_token');
        requestHeaders = {...requestHeaders, 'Authorization': `Bearer ${access_token}`}
    }
    try {
        const response = await axios({
            baseURL: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_ENV}/v1`,
            url: config.url,
            method: config.method ?? 'GET',
            headers: requestHeaders,
            data: JSON.stringify(config.data),
            ...other
        });
        
        if(response.status < 200 || response.status > 300) {  
            throw response.data; 
        }

        return response;

    } catch (error: any) {
        throw error.response.data;
    }
}


export { makeRequest };
