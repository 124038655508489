import { useState } from "react";
import { InputValue } from "../models/Input.model";

type useInputProps = (validator?: (input: InputValue) => [boolean, string | undefined]) => 
    {
        enteredInput: InputValue, 
        isValid: boolean, 
        hasError: boolean,
        errorMsg: string | undefined,
        setInputHandler: (value: InputValue) => void,
        setIsTouchedHandler: () => void,
        reset: () => void, 
        onSubmit: () => void
    }

const useInput: useInputProps = (validator) => {
    const [enteredInput, setEnteredInput] = useState<InputValue>(''),
        [inputIsTouched, setInputIsTouched] = useState(false)

    
    const setInputHandler = (value: InputValue) => {
        setEnteredInput(value)
    }

    const setIsTouchedHandler = () => {
        setInputIsTouched(true)
    }

    const reset = () => {
        setEnteredInput('')
        setInputIsTouched(false)
    }

    const onSubmit = () => {
        setInputIsTouched(true)
    } 
    const [isValid, errorMsg] = validator ? validator(enteredInput) : [true, ''],
        hasError = !isValid && inputIsTouched    

    return { enteredInput, isValid, hasError, errorMsg, setInputHandler, setIsTouchedHandler, reset, onSubmit }
}

export default useInput;