import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { authReducer } from "./auth-slice";
import { errorReducer } from "./error-slice";
import { toasterReducer } from "./toaster-slice";
import { uiReducer } from "./ui-slice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        error: errorReducer,
        toast: toasterReducer,
        ui: uiReducer,
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
