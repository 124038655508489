interface jwtIdentity {
    email: string,
    'custom:account_type': 'JOB_SEEKER' | 'RECRUITER',
    account_type: 'JOB_SEEKER' | 'RECRUITER',
    exp: number,
    iat: number
}

const parseJwt = (token: string): jwtIdentity => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )
    
    let obj = JSON.parse(jsonPayload);
    return {...obj, account_type: obj['custom:account_type']}
}

export { parseJwt };
