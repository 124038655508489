import Joi, { custom, types } from "joi";
import { InputValue } from "../models/Input.model";

interface Rule {
    [key: string]: Joi.StringSchema
}

type ValidatorType = (types: {
    [key: string]: string
}, input: InputValue) => [boolean, string | undefined]


/* All available rules for the Input elements
 **/
const rules = (param: string = 'Invalid', value: number = 0): Rule => {
    return {
        required: Joi.string().required(),
        min: Joi.string().empty('').min(3).message(param),
        email: Joi.string().empty('').email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } }).message(param),
        password: Joi.string().empty('').pattern(new RegExp('^[a-zA-Z0-9]{8,30}$')).message(param),
        phone: Joi.string().empty('').pattern(new RegExp('\d{10}$')).message(param)
    }
} 

/* Stores the Final Rule created for an Input Element 
 *  by Combining Multiple Rules... eg: min, email, required
 **/
const rule = {
    custom: Joi.string()
}

const validator: ValidatorType = (types, input) => {
    const ruleMessagePair = Object.entries(types);
    // Concat all the Rule types
    rule.custom = ruleMessagePair.reduce((totalRules, type) => totalRules.concat(rules(type[1])[type[0]]), Joi.string())
    // console.log(rule.custom);
    const schema = Joi.object({ input: rule.custom }).prefs({messages: { required: 'Was REALLY expecting a string'} })

    const result = schema.validate({ input: input });
    // Returns a boolean
    return [!!!result.error, result.error?.message];
}


export { validator };