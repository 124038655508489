import React from "react";
import { Button } from "../../components/Button/Button";
import { Form } from "../../components/Form/Form";
import { Input } from "../../components/Input/Input";
import { Select } from "../../components/Select/Select";
import styles from "./CreateJob.module.css";

export const CreateJob: React.FC = (props) => {

    
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ];

    const onSubmitHandler = (formData: object) => {
        
    }

    return (
        <div className={styles.create__job}>
                <h1 className="glow-text">Create a Job</h1>
            <div className={styles.header}>
                <p>Please fill all the required <span style={{ color: 'red' }}>*</span> fields.</p>
                <p>Note that the analysis is aided by how accurate the description is.</p>
                <a className="subtle-link subtle-text">How to work the algorithm?</a>
            </div>
            <Form onSubmit={ onSubmitHandler } className={styles.form}>
                <fieldset>
                    <Input name="title" label="Title" type="text"/>
                    <Input name="role" label="Role" type="text"/>
                    <Input name="position" label="Position" type="text"/>
                    <div className={styles.fields}>
                        <Input name="type" label="Type" type="text"/>
                        <Input name="ctc" label="CTC" type="number"/>
                    </div>
                </fieldset>
                <fieldset>
                    <Select isMulti={true} closeMenuOnSelect={false} label="Location" options={options} />
                    <Input name="qualification" label="Qualification" type="text"/>
                    <Input name="description" label="Description" type="text"/>
                </fieldset>
                <Button type="submit">Submit</Button>
            </Form>
        </div>
    );
} 