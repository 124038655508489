import styles from './Card.module.css';

interface CardProps {
    header?: JSX.Element,
    style?: any,
    className?: string,
    padding?: string,
    id?: string
}

export const Card: React.FC<CardProps> = (props) => {
    return (
        <div className={ `${ styles.card } ${ props.className }` } style={ props.style } id={props.id}>
            { props.header && (
                <div className={ styles.header }>
                    { props.header }
                </div> )
            }
            <div className={ styles.body } style={{ padding: props.padding ? props.padding : '1.5rem' }}>
                { props.children }
            </div>
        </div>
    );
} 