const ROUTES = {
    login: '/login',
    createAccount: '/create/account',
    profile: '/user/profile',
    jobs: '/jobs',
    createJob: '/job/create',
    jobProfiles: '/job/:id/profiles',
}

/**
 * Replace the :wildcards with values
 * @param route Route to manipulate
 * @param args Values to be replaced with
 * @returns Modified Route
 */
const addRouteParams = (route: string, ...args: any) => {
    // Check if the no. of wildcards in the route matches with the no. args given 
    const noParams = route.split(/:[a-z]+/).length - 1;
    if (noParams !== args.length) return '';
    
    let modifiedRoute = route;
    for (const arg of args) {        
        modifiedRoute = modifiedRoute.replace(/:[a-zA-Z]+/, arg);
    }
    
    return modifiedRoute;
}

export { ROUTES, addRouteParams };