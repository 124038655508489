import { createSlice } from "@reduxjs/toolkit"
import { IError } from "../models/Error.model"

interface ErrorInitialState {
    errors: IError[]
}

const initialState: ErrorInitialState = {
    errors: []
}

export const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        createError (state, action: { payload: IError }) { 
            state.errors.push(action.payload)
        },
        resolveError (state, action) {
            state.errors.filter(error => action.payload.includes(error.id))
        }
    }
})

export const errorReducer = errorSlice.reducer;
export const errorActions = errorSlice.actions;
