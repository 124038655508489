import { JobProfileFilters } from "../models/Profile.model";
import { API, SecuredAPI } from "./api";

/**
 * Fetch all curated Profiles for the job listing
 * @param filters: filters for filtering the profiles
 */
const fetchProfiles = (jobId: string, filters: JobProfileFilters) => {
    return SecuredAPI.post('/profiles/fetch', {
        body: {
            jobId,
            filters,
        }
    });
}

/**
 * Update the status of the Profile in a job listing
 * @param status: { bookmarked, selected } (both are boolean)
 */
const updateProfileStatus = (jobId: string, profileId: string, status: JobProfileFilters) => {
    return SecuredAPI.put('/profile/bookmark', {
        body: {
            jobId,
            profileId,
            status: {}
        }
    });
}

export { fetchProfiles, updateProfileStatus };