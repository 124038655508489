import { useParams } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import styles from './JobProfiles.module.css';
import { ReactComponent as CaretDownIcon } from "../../assets/svg/icon-caret-down.svg";
import { ReactComponent as BookmarkIcon } from "../../assets/svg/bookmark-icon.svg";

import { ProfileList } from "../../components/ProfileList/ProfileList";
import { useEffect, useState } from "react";
import { CollapsibleText } from "../../components/CollapsibleText/CollapsibleText";
import { JobProfileFilters } from "../../models/Profile.model";

interface job {
    id: string,
    title: string,
    description?: string,
    criteria: string[],
}


export const JobProfiles: React.FC = (props) => {
    
    const { id } = useParams();
    const [job, setJob] = useState<job>(),
        [query, setQuery] = useState<JobProfileFilters>({
            sort: 'asc',
            bookmarked: false,
            selected: false
        });

    useEffect(() => {
        // TODO: fetch job details
        setJob({
            id: 'job-2j9dnk-smka',
            title: 'Frontend Developer',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem vel nulla harum neque asperiores aspernatur, dolore ipsum recusandae amet unde voluptatum tempore itaque saepe rem beatae, consequatur quos necessitatibus? Laudantium ex delectus animi. Repellendus velit maxime adipisci beatae est praesentium numquam consequuntur nihil fugiat aliquid! Fugiat beatae quas, ea debitis voluptatibus explicabo nam. Asperiores rem et deserunt, consequatur dolores beatae ducimus labore voluptatem tempora quasi, optio magni? Maiores amet animi beatae.',
            criteria: ['React.js', 'Redux', 'API Integration'],
        })
    }, [id]);

    const toggleSort = () => {
        setQuery(query => ({
            ...query,
            sort: query.sort === 'asc' ? 'desc' : 'asc'
        }));
    }
    const toggleViewBookmarked = () => {
        setQuery(query => ({
            ...query,
            bookmarked: !query.bookmarked
        }));
    }
    const toggleViewSelected = () => {
        setQuery(query => ({
            ...query,
            selected: !query.selected
        }));
    }
    
    console.log(query);

    const header = (
        <div className={styles.header}>
            <div className={styles.titlebar}>
                <h3>Profiles</h3>
                <div style={{ display: 'flex', gap: '0.5em' }}>
                    <button className={`${styles['bookmark-button']} ${query.bookmarked && styles.marked}`} onClick={ toggleViewBookmarked }>
                        <BookmarkIcon />
                    </button>
                    <button className={`${styles['selected-button']} ${query.selected && styles.marked}`} onClick={ toggleViewSelected }>
                        Selected
                    </button>
                </div>
            </div>
            <ul>
                <li>Personal Details</li>
                <li>
                    Avg. Score
                    <CaretDownIcon className={`${query.sort == 'desc' && 'rotate-180'}`} onClick={ toggleSort } />
                </li>
                <li>Actions</li>
            </ul>
        </div>
    )
    return (
        <>
        { job && 
            <>
                <h1 className="glow-text">Job Profiles</h1>
                <p>List of all the profile matching job <span className="subtle-text">#{id}</span></p>
                <div className={ styles.job }>
                    <h4>{job.title}</h4>
                    <ul className="dot-separated-list">
                        {job.criteria.map((item, idx) => <li key={idx}>{item}</li>)}
                    </ul>
                    <CollapsibleText maxHeight="2rem">
                        {job.description}
                    </CollapsibleText>
                </div>
                <Card header={header} padding="0" className={styles['profile-card']}>
                    <ProfileList jobId={job.id} query={query} />
                </Card>
            </>
        }
        </>
    );
}