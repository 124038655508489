import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import { Toaster } from './components/Toaster/Toaster';
import { AuthLayout } from './pages/Auth/AuthLayout';
import { LoginPage } from './pages/Auth/LoginPage';
import { SignUpPage } from './pages/Auth/SignUpPage';
import { UserProfile } from './pages/UserProfile';
import { useAppSelector } from './store/store';
import { JobProfiles } from './pages/Job/JobProfiles';
import { AppLayout } from './pages/AppLayout';
import { Jobs } from './pages/Job/Jobs';
import { CreateJob } from './pages/Job/CreateJob';
import { ROUTES } from './routes';

function App() {

  const toasts = useAppSelector(state => state.toast.toasts);
  const authState = useAppSelector(state => ({ isLoggedIn: state.auth.logged_in, accountType: state.auth.account_type}))
  
  const fallbackRoute = authState.isLoggedIn ? ROUTES.profile : ROUTES.login;

  return (
    <div className="App">
      <div className="content">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AuthLayout><Outlet /></AuthLayout>}>
              <Route path={ROUTES.login} element={<LoginPage />} />
              <Route path={ROUTES.createAccount} element={<SignUpPage />} />
            </Route>
            { authState.isLoggedIn &&
              <Route element={<AppLayout><Outlet /></AppLayout>}>
                <Route path={ROUTES.profile} element={<UserProfile />} />
                <Route path={ROUTES.jobs} element={<Jobs />} />
                <Route path={ROUTES.createJob} element={<CreateJob />} />
                <Route path={ROUTES.jobProfiles} element={<JobProfiles />} />
              </Route>
            }
            <Route path="*" element={<Navigate to={fallbackRoute} />} />
          </Routes>
        </BrowserRouter>
      </div>
      <div className="toaster-zone">
        { toasts.map((toast, idx) => <Toaster id={toast.id} key={idx} title={toast.title} message={toast.message} type={toast.type} />) }
      </div>
    </div>
  );
}

export default App;
