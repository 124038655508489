import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IToast } from "../../models/Toast.model";
import { toasterActions } from "../../store/toaster-slice";
import styles from "./Toaster.module.css";

interface ToasterProps extends IToast {
    onClick?: (e: React.MouseEvent) => {}
}
let timer: ReturnType<typeof setTimeout>;

export const Toaster: React.FC<ToasterProps> = (props) => {
    const { id, type, title, message, onClick } = props;
    const [isOpen, setIsOpen] = useState<boolean>(true)
    const dispatch = useDispatch();

    useEffect(() => {
        timer = setTimeout(closeHandler, 5000);
    }, []);

    const closeHandler = useCallback(() => {
        setIsOpen(false);
        clearTimeout(timer);
        setTimeout(_ => dispatch(toasterActions.removeToast(id)), 600)       
    }, [timer]);
    
    const className = `
        ${ styles.toaster }
        ${ !isOpen ? styles.closed : '' }
        ${ type === 'success' ? styles.success : '' }
        ${ type === 'error' ? styles.error : '' }
        ${ type === 'info' ? styles.info : '' }
        ${ type === 'warning' ? styles.warning : '' }`

    return (
        <div className={ className } onClick={ onClick }>
            <div>
                <h4>{ title }</h4>
                <p>{ message }</p>
            </div>
            <button className="icon-button" onClick={ closeHandler }>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
            </button>
        </div>
    );
}
