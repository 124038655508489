import { Input } from '../../components/Input/Input';
import { Form } from '../../components/Form/Form';
import { Card } from '../../components/Card/Card';
import { Button } from '../../components/Button/Button';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as EyeOpen } from "../../assets/svg/icon-eye-open.svg";
import { ReactComponent as EyeClose } from "../../assets/svg/icon-eye-close.svg";
import { ROUTES } from '../../routes';
import { loginUser } from '../../api/auth.api';
import { IUserCredentials } from '../../models/User.model';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/store';
import { toasterActions } from '../../store/toaster-slice';

export const LoginPage: React.FC = (props) => {
    
    const [isVisible, setIsVisible] = useState(false);
    const dispatch = useDispatch();
    const authState = useAppSelector(state => state.auth)
    const navigate = useNavigate();

    useEffect(() => {
        console.log(authState);
        
    }, [authState])
    
    const onToggleHandler = () => {
        setIsVisible (state => state = !state);
    }
   
    
    const onSubmitHandler = (formData: IUserCredentials) => {
        loginUser(formData)
            .then(res => {                
                dispatch({ type: 'login', payload: res.data.Message.JWT })
                navigate(ROUTES.profile)
            })
            .catch(err => dispatch(toasterActions.addToast({
                id: 232,
                title: 'Error',
                message: err.Message,
                type: 'error'
            })))
    }

    return (
        <div className="auth-page__wrapper">
            <h1 className="glow-text">Jobs that statisfy...</h1>
            <Card style={{ width: `23.5rem` }}>
                <header style={{ marginBottom: `2.5rem` }}>
                    <h2>Sign in</h2>
                    <p style={{ marginTop: `0.5rem` }} className="subtle-text">Get started</p>
                </header>
                <Form style={{ margin: '1.5rem 0' }} onSubmit={ onSubmitHandler }>
                    <Input type="email" name="Email" label="Email" validator={{
                        min: 'Must be a min 3',
                        email: 'Enter a valid Email',
                        required: 'Is required!!',
                    }} />
                    <Input name="PassWord" type={ isVisible ? 'text' : 'password' } label="Password" 
                    validator={{
                        min: 'Minimum of 3 characters', 
                        password: 'Must be a password'
                    }} icon={ isVisible ? <EyeOpen /> : <EyeClose /> } onToggle={onToggleHandler} />
                    <Link to="/forgot-password" className="subtle-text text-sm" style={{ textAlign: 'right' }}>Forgot password?</Link> 
                    <Button type="submit">Submit</Button>
                </Form>
                <p className="text-sm" style={{ textAlign: 'center' }}>
                    <span className="subtle-text">New here? </span>
                    <Link to={ROUTES.createAccount}>Sign up</Link>
                </p>
            </Card>
        </div>
    );
}