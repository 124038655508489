import { memo, useEffect } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import useInput from "../../hooks/use-input";
import { InputValue } from "../../models/Input.model";
import { validator } from "../../utils/Validator";
import styles from "./Input.module.css";

export interface InputProps {
    name: string;
    label: string,
    type: string,
    value?: InputValue,
    placeholder?: string,
    disabled?: boolean,
    icon?: JSX.Element,
    style?: {},
    className?: string,
    onToggle?: React.MouseEventHandler,
    validator?: {
        [key: string]: string
    }
}

export const Input: React.FC<InputProps> = (props) => {
    const methods = useFormContext();
    return <InputElement {...props} {...methods} />
}

const InputElement: React.FC<InputProps & UseFormReturn> = memo((props) => {
    let validation;
    if (props.validator) {
        // Generate the validation function
        validation = validator.bind(this, props.validator)
    }
    const { enteredInput, isValid, hasError, errorMsg, setInputHandler, setIsTouchedHandler, reset, onSubmit } = useInput(validation)    

    useEffect(() => {
        if (props.value) setInputHandler(props.value);
    }, [props.value]);

    return (
        <div className={`${ styles.field } ${ props.className }`} style={ props.style }>
            <label>{ props.label }</label>
            <input type={ props.type } className={`${styles.input} ${hasError && styles.error}`} placeholder={ props.placeholder } disabled={ props.disabled } {...props.register(props.name)} />
            <div className={`${ styles.icon } ${ props.onToggle ? styles.clickable : '' }`} onClick={props.onToggle}>
                {props.icon}
            </div>
            { hasError && <span className={styles.msg}>{errorMsg}</span> }
        </div>
    );
}, (prevProps, nextProps) => prevProps.formState.isDirty === nextProps.formState.isDirty)