import { ICreateUser, IUserCredentials } from "../models/User.model"
import { API, makeRequest } from "./api"
import { API_ENDPOINTS } from "./map.api"

// const login = (user: IUserCredentials) => API.post(API_ENDPOINTS.login, user);
const loginUser = (user: IUserCredentials) => makeRequest({
    url: API_ENDPOINTS.login,
    method: 'POST',
    data: user
});

const createAccount = (user: ICreateUser) => makeRequest({
    url: API_ENDPOINTS.createAccount,
    method: 'POST',
    data: user
});

export { loginUser, createAccount };