import { createSlice } from "@reduxjs/toolkit";
import { parseJwt } from "../utils/jwt";
import { store } from "./store";

interface authStateType {
    access_token: string | null,
    email: string | null,
    account_type: string | null, 
    logged_in: boolean,
    issued_at: number,
    expires_at: number,
}

const ACCESS_TOKEN = localStorage.getItem('access_token') || null,
    LOGGIN_STATE = !!localStorage.getItem('access_token')


let initialState: authStateType = {
    access_token: null,
    email: null,
    account_type: null, 
    logged_in: false,
    issued_at: Date.now(),
    expires_at: Date.now() + 30,
}

if (!!ACCESS_TOKEN) {
    const { email, account_type, iat, exp, ...rest } = parseJwt(ACCESS_TOKEN)
    initialState = {
        access_token: ACCESS_TOKEN,
        email,
        account_type,
        issued_at: iat,
        expires_at: exp,
        logged_in: LOGGIN_STATE,
    }
}

/**
 * The timer that keeps track of the duration till expiry
 * - once the timer is complete the user is logged out.
 */

let loginTimer: ReturnType<typeof setTimeout>;

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout (state) {
            localStorage.removeItem('access_token');
            clearTimeout(loginTimer);
            return {
                access_token: null,
                email: null,
                account_type: null, 
                logged_in: false,
                issued_at: Date.now(),
                expires_at: Date.now(),
            };
        }
    },
    extraReducers: {
        login: (state, action) => {
            localStorage.setItem('access_token', action.payload)
            const { exp, iat, email, account_type } = parseJwt(action.payload);
            
            // Clear previous timeout if set 
            if (!!loginTimer) clearTimeout(loginTimer);

            const duration = (exp - iat) * 1000;
            loginTimer = setTimeout(() => {
                store.dispatch({type: 'auth/logout'});
            }, duration);

            return {
                access_token: action.payload,
                email,
                account_type,
                logged_in: true,
                expires_at: exp,
                issued_at: iat,
            };
        },
        refreshToken (state, action) {
            localStorage.setItem('access_token', action.payload);
            // store.dispatch({ type: 'login', payload: action.payload })
        }
    }
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
