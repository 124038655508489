import styles from "./ExpandableList.module.css";
import { ExpandableItem } from "./ExpandableItem";
import React from "react";


export interface ExpandableProps<T> {
    item: React.FC<{ data: any, expand: React.Dispatch<React.SetStateAction<boolean>>, isExpanded?: boolean }>,
    expand: React.FC<{ data: any, isExpanded?: boolean }>,
    data: T
}

export const ExpandableList: React.FC<ExpandableProps<any[]>> = (props) => {
    
    return (
        <ul className={styles.list}>
            {
                props.data.map((item, idx) => 
                    <ExpandableItem key={idx} item={props.item} expand={props.expand} data={item} />
                )
            }
        </ul>
    );
}