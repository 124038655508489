import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import classes from "./Form.module.css";

interface FromProps<T extends { [key: string]: any }> {
    onSubmit: (data: T) => void,
    style?: any,
    className?: string,
    defaultValues?: FieldValues,
    children: React.ReactChild[] | React.ReactChildren[]
}

export const Form = <T extends { [key: string]: any }>(props: FromProps<T>) => {

    const methods = useForm({ defaultValues: props.defaultValues });

    const onSubmitHandler: SubmitHandler<FieldValues> = (formData) => {
        props.onSubmit(formData as T);
    }

    return (
        <FormProvider {...methods}>
            <form className={ `${classes.form} ${props.className}` } onSubmit={ methods.handleSubmit(onSubmitHandler) } style={ props.style } 
                autoComplete="false" noValidate>
                { props.children }
            </form>
        </FormProvider>
    );
}