import { Input } from '../../components/Input/Input';
import { Form } from '../../components/Form/Form';
import { Card } from '../../components/Card/Card';
import { Button } from '../../components/Button/Button';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as EyeOpen } from "../../assets/svg/icon-eye-open.svg";
import { ReactComponent as EyeClose } from "../../assets/svg/icon-eye-close.svg";
import { ROUTES } from '../../routes';
import { ICreateUser } from '../../models/User.model';
import { createAccount } from '../../api/auth.api';
import styles from './SignUpPage.module.css';

export const SignUpPage: React.FC = (props) => {
    
    const [isVisible, setIsVisible] = useState(false),
        [type, setType] = useState<'JOB_SEEKER' | 'RECRUITER'>('JOB_SEEKER');
    
    const onToggleHandler = () => {
        setIsVisible (state => state = !state);
    }
    
    const onSubmitHandler = (formData: ICreateUser) => {
        console.log(formData);
        
        // createAccount(formData)
    }


    return (
        <div className="auth-page__wrapper">
            <h1 className="glow-text">One step closer to {type === 'JOB_SEEKER' ? <>get hired</> : <>start hiring</> }...</h1>
            <Card style={{ width: `23.5rem` }}>
                <header style={{ marginBottom: `2.5rem` }}>
                    <h2>Sign up as <br />
                        <span className={`${styles.type} ${type === 'JOB_SEEKER' && styles.active}`} onClick={_=>setType('JOB_SEEKER')}> Job Seeker</span>
                        <span className={`${styles.type} ${type === 'RECRUITER' && styles.active}`} onClick={_=>setType('RECRUITER')}> Recruiter</span>
                    </h2>
                    <p style={{ marginTop: `0.5rem` }} className="subtle-text">Get started</p>
                    {/* <div className={styles.tabs}>
                        <Button className={`${styles.tab} ${type === 'JOB_SEEKER' && styles.active}`} onClick={_=>setType('JOB_SEEKER')}>Job Seeker</Button>
                        <Button className={`${styles.tab} ${type === 'RECRUITER' && styles.active}`} onClick={_=>setType('RECRUITER')}>Recruiter</Button>
                    </div> */}
                </header>
                <Form style={{ margin: '1.5rem 0' }} onSubmit={ onSubmitHandler }>
                    <Input name="fullname" type="text" label="Full name" />
                    <Input name="email" type="text" label="Email" />
                    <Input name='password' type={ isVisible ? 'text' : 'password' } label="Password" icon={ isVisible ? <EyeOpen /> : <EyeClose /> } onToggle={onToggleHandler} />
                    <Button type="submit">Submit</Button>
                </Form>
                <p className="text-sm" style={{ textAlign: 'center' }}>
                    <span className="subtle-text">Already a member?</span> <Link to={ROUTES.login}>Sign in</Link>
                </p>
            </Card>
        </div>
    );
}