import React from "react";
import styles from "./AuthLayout.module.css";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as Circles } from "../../assets/svg/concentric-circles.svg";
import { ReactComponent as AnalyticsLogo } from "../../assets/svg/icon-data-analytics.svg";
import { ReactComponent as BigDataLogo } from "../../assets/svg/icon-big-data.svg";
import { ReactComponent as DevLogo } from "../../assets/svg/icon-development.svg";

export const AuthLayout: React.FC = (props) => {
    return (
        <div className={ styles.layout }>
            <div className={ styles.brand }>
                <Logo className={ styles['brand-logo'] } />
                <div className={ styles['brand-description'] }>
                    <p>RECRUITER</p>
                    <p className="subtle-text">Workforce Simplified</p>
                </div>
            </div>
            { props.children }
            <div className={styles.circles}>
                <span className={styles['circles-icon']}>
                    <AnalyticsLogo />
                </span>    
                <span className={styles['circles-icon']}>
                    <BigDataLogo />
                </span>
                <span className={styles['circles-icon']}>
                    <DevLogo />
                </span>
                <span className={styles['circles-icon']}>
                    <DevLogo />
                </span>
                <Circles className={styles['circles-bg']} />
            </div>
        </div>
    );
}