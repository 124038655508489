import { useState } from "react";
import { Props } from "react-select";
import SelectComponent from 'react-select';


const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        width: '100%',
        height: '2.675rem',
        borderRadius: '0.85rem',
        color: '#FFFFFF',
        backgroundColor: 'transparent',
        boxShadow: 'none'
    }),
    placeholder: (provider: any) => ({
        ...provider,
        padding: '0 0.25rem',
    }),
    valueContainer: (provider: any) => ({
        ...provider,
        padding: '0 0.25rem',
    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        color: '#FFFFFF',
    }),
    multiValue: (provider: any) => ({
        ...provider,
        padding: 4,
        borderRadius: '0.5rem',
    }),
    multiValueLabel: (provider: any) => ({
        ...provider,
        color: '#FFFFFF',
    }),
    dropdownIndicator: (provider: any, state: any) => ({
        ...provider,
        color: '#FFFFFF',
    }),
    indicatorSeparator: () => ({
        borderRight: '0px'
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        color: '#FFFFFF',
        padding: '0.5rem 1rem',
        backgroundColor: 'transparent'
    }),
    menu: (provider: any) => ({
        ...provider,
        color: '#FFFFFF',
        borderRadius: '0.5rem',
        overflow: 'hidden',
    }), 
}


interface SelectProps extends Props {
    label: string
}

export const Select: React.FC<SelectProps> = (props) => {
    const { label, styles, classNamePrefix, onChange, children, ...properties } = props;
    const [selected, setSelected] = useState<{ value: string; label: string; }>();

    const onSelectHandler = (value: any) => {
        setSelected(value);
    }    

    console.log(selected);
    
    return (
        <div>
            <label style={{ display: 'block', fontSize: '0.8rem', padding: '0.2rem 0.4rem' }}>{ label }</label>
            <SelectComponent onChange={onSelectHandler} styles={customStyles} classNamePrefix="custom-select" {...properties} />
        </div>
    );
}