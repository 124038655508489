import styles from "./CollapsibleText.module.css";
import { useState } from "react";

interface CollapsibleTextProps {
    maxHeight: string
}

export const CollapsibleText: React.FC<CollapsibleTextProps> = (props) => {
   
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    return (
        <div className={styles.collapsible}>
            <p style={{ maxHeight: props.maxHeight }} className={isCollapsed ? '' : styles.expanded}>
                { props.children }
            </p>
            <span className={ styles.action } onClick={e => setIsCollapsed(state => !state)}>{ isCollapsed ? '+ show more' : '- show less' }</span>
        </div>
    );
}