import { useEffect, useState } from "react";
import { fetchProfiles } from "../../api/profile.api";
import { JobProfileFilters, Profile } from "../../models/Profile.model";
import { ExpandableList } from "../ExpandableList/ExpandableList"
import { ProfileExpand, ProfileItem } from "./ProfileItem";

const INITIAL_DATA = [
    {
        id: 'l02en-wnk123-q102q',
        name: 'Oskar Shiff',
        skills: ['Frontend Developer', 'Vue.js', 'React.js', 'UI/UX', 'GitHub', 'Alipine.js', 'Redux'],
        currentCTC: '12.4 LPA',
        expectedCTC: '18.4 LPA',
        workExperience: '3 years',
        locations: ['Chennai', 'Bangalore', 'Hyderabad', 'WFH'],
        score: 9195,
        avatar: 'https://cdn.pixabay.com/photo/2019/12/04/09/30/man-4672229_960_720.jpg',
        resume: '',
        favourite: false,
        selected: true
    },
    {
        id: 'l02en-wnk123-q102a',
        name: 'Liya Thomas',
        skills: ['Frontend Developer', 'React.js', 'UI/UX', 'GitHub', 'Alipine.js', 'Redux'],
        currentCTC: '15.0 LPA',
        expectedCTC: '20.2 LPA',
        workExperience: '2 years',
        locations: ['Bangalore', 'WFH'],
        score: 9201,
        avatar: 'https://cdn.pixabay.com/photo/2019/02/11/20/20/woman-3990680_960_720.jpg',
        resume: '',
        favourite: true,
        selected: false
    },
    {
        id: 'l02en-wnk123-q102k',
        name: 'James Wheaton',
        skills: ['Frontend Developer', 'Angular.js', 'Svelte.js', 'UI/UX', 'GitHub'],
        currentCTC: '9.56 LPA',
        expectedCTC: '15.0 LPA',
        workExperience: '3 years',
        locations: ['WFH'],
        score: 9195,
        avatar: 'https://cdn.pixabay.com/photo/2015/01/12/10/45/man-597178_960_720.jpg',
        resume: '',
        favourite: false,
        selected: true
    },
    {
        id: 'l02en-wnk123-q102q',
        name: 'Oskar Shiff',
        skills: ['Frontend Developer', 'Vue.js', 'React.js', 'UI/UX', 'GitHub', 'Alipine.js', 'Redux'],
        currentCTC: '12.4 LPA',
        expectedCTC: '18.4 LPA',
        workExperience: '3 years',
        locations: ['Chennai', 'Bangalore', 'Hyderabad', 'WFH'],
        score: 9195,
        avatar: 'https://cdn.pixabay.com/photo/2019/12/04/09/30/man-4672229_960_720.jpg',
        resume: '',
        favourite: false,
        selected: false
    },
    {
        id: 'l02en-wnk123-q102a',
        name: 'Liya Thomas',
        skills: ['Frontend Developer', 'React.js', 'UI/UX', 'GitHub', 'Alipine.js', 'Redux'],
        currentCTC: '15.0 LPA',
        expectedCTC: '20.2 LPA',
        workExperience: '2 years',
        locations: ['Bangalore', 'WFH'],
        score: 9201,
        avatar: 'https://cdn.pixabay.com/photo/2019/02/11/20/20/woman-3990680_960_720.jpg',
        resume: '',
        favourite: true,
        selected: false
    },
    {
        id: 'l02en-wnk123-q102k',
        name: 'James Wheaton',
        skills: ['Frontend Developer', 'Angular.js', 'Svelte.js', 'UI/UX', 'GitHub'],
        currentCTC: '9.56 LPA',
        expectedCTC: '15.0 LPA',
        workExperience: '3 years',
        locations: ['WFH'],
        score: 9195,
        avatar: 'https://cdn.pixabay.com/photo/2015/01/12/10/45/man-597178_960_720.jpg',
        resume: '',
        favourite: false,
        selected: false
    }
]
interface ProfileListProps {
    jobId: string, 
    query: JobProfileFilters
}
export const ProfileList: React.FC<ProfileListProps> = (props) => {
    const [profiles, setProfiles] = useState<Profile[]>();
        
    useEffect(() => {
        // TODO: Fetch job profiles
        fetchProfiles(props.jobId, props.query)
        
        setProfiles(INITIAL_DATA);
    }, [props.jobId, props.query]);

    return (
        <>
            { profiles && <ExpandableList data={profiles} item={ProfileItem} expand={ProfileExpand} /> }
        </>
    );
}