import styles from "./ProfileItem.module.css";
import { ReactComponent as BookmarkIcon } from "../../assets/svg/bookmark-icon.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/svg/chevron-down-icon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/svg/download-icon.svg";
import { useState } from "react";

interface ProfileType {
    id: string,
    name: string,
    skills: string[],
    currentCTC: string,
    expectedCTC: string,
    workExperience: string,
    locations: string[],
    score: number,
    avatar: string,
    resume: string
    bookmarked: boolean,
    selected: boolean,
}

export const ProfileItem: React.FC<{ data: ProfileType, expand: any, isExpanded?: boolean }> = (props) => {
    const { id, name, skills, score, avatar, bookmarked, selected } = props.data;
    const [isBookmarked, setIsBookmarked] = useState<boolean>(bookmarked),
        [isSelected, setIsSelected] = useState(selected);

    const toggleBookmarked = () => {
        // TODO: Call API with value = !bookmarked
        const data = {
            jobId: 'job-9209ka-12uia',
            profileId: id,
            bookmarked: !bookmarked
        }
        setIsBookmarked(state => !state);
    }

    const toggleSelected = () => {
        // TODO: Call API with value !selected
        const data = {
            jobId: 'job-9209ka-12uia',
            profileId: id,
            selected: !selected
        }
        setIsSelected(state => !state);
    }

    return (
        <div className={styles['profile-item']}>
            <div className={styles.details}>
                <img className="avatar" src={ avatar } alt="user-avatar"/>
                <div>
                    <h4>{ name }</h4>
                    <ul className="dot-separated-list">
                        {skills.map((skill, idx) => idx < 3 && <li key={idx}>{skill}</li>)}
                    </ul>
                    { !props.isExpanded && <span className="subtle-text">...{skills.length - 3}more</span> }
                </div>
            </div>
            <div className={styles.score}>{ score }</div>
            <div className={styles.actions}>
                { isSelected ? 
                    <button className={`tertiary-button ${styles.selected}`} onClick={ toggleSelected }>Deselect</button> :
                    <button className="tertiary-button" onClick={ toggleSelected }>Select</button> 
                }
                <BookmarkIcon className={`star ${ isBookmarked ? 'starred' : '' }`} onClick={ toggleBookmarked }/>
                <button onClick={e => props.expand((state: boolean) => !state)}>
                    <ChevronDownIcon className={ props.isExpanded ? 'rotate-t-180' : 'rotate-t-0'} />
                </button>
            </div>
        </div>
    );
} 


export const ProfileExpand: React.FC<{ data: ProfileType }> = (props) => {
    const { skills, currentCTC, expectedCTC, workExperience, locations, resume } = props.data;
    
    return (
        <div className={styles['profile-expandable']}>
            <div className={styles.skills}>
                <h5>Skills</h5>
                <ul>
                    { skills.map((skill, idx) => <li key={idx}>{skill}</li>) }
                </ul>
            </div>
            <div className={ styles.pay }>
                <div>
                    <h5>Current CTC</h5>
                    <p>{ currentCTC }</p>
                </div>
                <div>
                    <h5>Expected CTC</h5>
                    <p>{ expectedCTC }</p>
                </div>
            </div>
            <div className={ styles.other }>
                <div>
                    <h5>Work Exp.</h5>
                    <p>{ workExperience }</p>
                </div>
                <div>
                    <h5>Locations</h5>
                    <p>{ locations.join(', ') }</p>
                </div>
            </div>
            <div className={ styles.actions }>
                <button className="border-button" data-href={resume}>
                    <DownloadIcon />
                    Resume
                </button>
            </div>
        </div>
    )
} 