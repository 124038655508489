import { createSlice } from "@reduxjs/toolkit"
import { IToast } from "../models/Toast.model"

interface ToasterInitialState {
    toasts: IToast[]
}

const initialState: ToasterInitialState = {
    toasts: []
}

export const toasterSlice = createSlice({
    name: 'toaster',
    initialState,
    reducers: {
        addToast (state, action: { payload: IToast }) { 
            const id = `${ action.payload.id }_${ state.toasts.length }`;
            state.toasts.push({...action.payload, id});
        },
        removeToast (state, action) { 
            return {
                toasts: state.toasts.filter(toast => toast.id !== action.payload)
            }
        }
    }
})

export const toasterReducer = toasterSlice.reducer;
export const toasterActions = toasterSlice.actions;
