import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setIsLoading (state, action) {
            return action.payload;
        }
    }
});

export const uiReducer = uiSlice.reducer;
export const uiActions = uiSlice.actions;