import styles from './Sidebar.module.css';
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as CompanyIcon } from "../../assets/svg/company-icon.svg";
import { ReactComponent as MailIcon } from "../../assets/svg/mail-icon.svg";
import { ReactComponent as DocumentCopyIcon } from "../../assets/svg/document-copy-icon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/svg/dashboard-icon.svg";
import { ReactComponent as JobIcon } from "../../assets/svg/job-icon.svg";
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ROUTES } from '../../routes';

interface SidebarProps {
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>();

    useEffect(() => {
        setIsMobile(window.length <= 480);
        props.setIsCollapsed(isCollapsed && isMobile)
    }, [window]);

    const toggleSidebar = () => {
        setIsCollapsed(state => !state);
    }

    return (
        <div className={`${styles.sidebar} ${(isMobile && isCollapsed) ? styles.hide : ''}`}>
            <div className={ styles.brand }>
                <Logo className={ styles['brand-logo'] } />
                <div className={ styles['brand-description'] }>
                    <p>RECRUITER</p>
                    <p className="subtle-text">Workforce Simplified</p>
                </div>
            </div>
            <ul>
                <li>
                    <NavLink to="/">
                        <DashboardIcon />
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/">
                        <CompanyIcon />
                        <span>Companies</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES.jobs}>
                        <JobIcon />
                        <span>Jobs</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/">
                        <DocumentCopyIcon />
                        <span>Matches</span>
                    </NavLink>
                </li>
            </ul>
            <div className={styles.footer}>
                &#169; Copyrights 2022
            </div>
        </div>
    );
}
